var _ = {
      throttle: function(func, wait) {
        var timer = null;

        return function() {
          var context = this,
              args = arguments;

          if(timer === null) {
            timer = setTimeout(function() {
              func.apply(context, args);
              timer = null;
            }, wait);
          }
        };
      },
    };

    var ScrollSticker = function($elem, options) {
        var initialPosition = 0;
        var targetTop = 0;
        var maxTranslation = 0;
        var $window = $(window);
        var isSticking = false;
        var width = 0;
        var right = 0;
        var parentHeight = 0;
        var offset = options.offset || 0;

        var removeSticky = function () {
          $elem.css('transform', 'none');
          $elem.css('position', 'relative');
          $elem.css('width', 'auto');
          $elem.css('right', 0);
          $elem.css('top', 0);
          $elem.parent().css('height', 'auto');
          $elem.removeClass('sticky');
        };

        var onScroll = function () {
          if ($(window).outerWidth() < 737) {
            removeSticky();
            return;
          }

          console.log('scrollTop: ', $window.scrollTop(), maxTranslation - offset - 100);

          if ($window.scrollTop() > (targetTop - offset)) {
            removeSticky();
            $elem.css('transform', 'translateY(' + maxTranslation + 'px)');
          } else if ($window.scrollTop() > (initialPosition - offset) ) {
            $elem.parent().css('height', parentHeight);
            $elem.css('transform', 'none');
            $elem.css('position', 'fixed');
            $elem.css('width', width);
            $elem.css('right', right);
            $elem.css('top', offset + 'px');
            $elem.addClass('sticky');
          } else {
            removeSticky();
          }
        }

        var onResize = function () {
          // recalculate the initialPosition and targetTop.
          // This is done in the resize event handler to
          // have as little computation as possible in the on scroll handler.
          targetTop = $(window).scrollTop() + options.target[0].getBoundingClientRect().top;
          initialPosition = $(window).scrollTop() + $elem[0].getBoundingClientRect().top;
          maxTranslation = targetTop - initialPosition;
          width = $elem.parent().width();
          right = $(window).width() - $elem[0].getBoundingClientRect().right;
          parentHeight = $elem.parent()[0].getBoundingClientRect().height + 'px';
        };
        var throttledOnResize = _.throttle(onResize, 100, { leading: false });

        var initialize = function () {
          $(window).on('scroll', onScroll);
          $(window).on('resize', throttledOnResize);
          //onResize();
          setTimeout(function () {
            onResize();
            onScroll();
          }, 10);

        };
        initialize();
      };

  export default ScrollSticker;
