'use strict';

var ScrollSticker = require('./scroll-sticker.js');

var _ = {
      throttle: function(func, wait) {
        var timer = null;

        return function() {
          var context = this,
              args = arguments;

          if(timer === null) {
            timer = setTimeout(function() {
              func.apply(context, args);
              timer = null;
            }, wait);
          }
        };
      },
    };

var ApproachModule = function ($elem) {
  var $stickerTarget = $elem.find('#approach__communication');
  var $figure = $elem.find('.approach-module__figure');
  var $sections = $elem.find('.approach-module__section');
  var currentTarget = null;
  var offset = 100;

  var scrollSticker = new ScrollSticker($figure, {
    target: $stickerTarget,
    offset: offset
  });

  var changeFigure = function (key) {
    $figure.find('.active').removeClass('active');
    $figure.find('[data-approach-image="' + key + '"]').addClass('active');
  };

  var throttledOnScroll = _.throttle(function () {
    var target = null;
    // find first figure that is not above screen
    for (var i = 0; i < $sections.length; i++) {
      if ($sections[i].getBoundingClientRect().bottom > offset) {
        target = $sections[i].id;
        break;
      }
    }

    if (target != null && target != currentTarget) {
      currentTarget = target;
      changeFigure(target);
    }
  }, 50, { leading: false });

  $(window).on('scroll', throttledOnScroll);
}

export default ApproachModule;
