// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

var ApproachModule = require('./components/approach-module.js');

document.addEventListener("DOMContentLoaded", function () {

  $('.approach-module').each(function () {
    new ApproachModule($(this));
  });
});
